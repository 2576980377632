.uploadBtn {
	display: inline-block;
	width: 100px;
	padding: 50px 0 0 0;
	height: 50px;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
		center center no-repeat #e4e4e4;
	border-radius: 20px;
	background-size: 40px 40px;
}
.testt {
	font-size: 100px;
}
