.time-header {
	width: 100%;
	height: 100px;
	text-align: center;
	/* border-top: 1px solid #fff; */
	color: #1e272e;
	display: flex;
	justify-content: center;
	background-color: #f1f2f6;
	font-size: 12px;
}

.day-time-intersection-box {
	width: 14.2857%;
	height: 100px;
	border-left: 0.1px solid rgba(0, 0, 0, 0.26);
	border-top: 0.1px solid rgba(0, 0, 0, 0.322);
	cursor: pointer;
	color: white;
	font-size: small;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.day-time-intersection-box:hover {
	background-color: #eee;
}

.day-time-intersection-box:active {
	cursor: grab;
}

.schedulerHeader {
	width: 14.2857%;
	height: 50px;
	text-align: center;
	padding: 15px 0;
	background-color: #f1f2f6;
	color: #1e272e;
	/* borderleft: "1px solid #fff"; */
}

.blockName {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 95%;
	width: 95%;
	border-radius: 10px;
	position: relative;
	z-index: 0;
}

.buttons {
	position: fixed;
	right: 50px;
	top: 150px;
	padding: 20px;
	background-color: #eee;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media only screen and (max-width: 600px) {
	.time-header {
		height: 50px;
		text-align: center;
	}

	.day-time-intersection-box {
		height: 50px;
	}
}
