.meetingsBg {
	/* background: url("../../assets/desktop_bg.png"); */
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	min-height: 100vh;
	position: relative;
}

.positionCSS {
	min-height: 100vh;
	position: relative;
}
