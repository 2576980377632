.card-teacher-meeting {
	width: 100%;
	height: 100%;
	padding-bottom: 60px;
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
	overflow: hidden;
}
.time-chip {
	position: absolute;
	bottom: 40px;
	left: -10px;
}

.timer-teacher-card {
	position: absolute;
	bottom: 10px;
	left: -10px;
}

.teacher-meeting-card-buttons {
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: flex;
	gap: 10px;
}
