.rewards-container {
	min-height: 100vh;
	margin-bottom: 100px;
}

.note-text {
	text-align: center;
	color: #aaa;
	padding: 30px;
}

.rewards-grad {
	background: #2ecc71; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #2ecc71, #27ae60); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#2ecc71,
		#27ae60
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	height: 230px;
	width: 100%;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.unflip {
	padding-top: 70px;
}

.reward-points-title {
	font-size: 20px;
	font-weight: 500;
	color: white;
}

.coin-background {
	margin-top: 15px;
	height: 65px;
	width: 65px;
	border-radius: 10px;
	background-color: rgba(0, 162, 103, 0.47);
	display: flex;
	justify-content: center;
	align-items: center;
}
.coin-img {
	height: 50px;
	width: 50px;
	object-fit: contain;
}
.rewards-point-value {
	font-size: 40px;
	font-weight: 500;
	color: white;
	margin-top: 20px;
	margin-left: 20px;
}

.rewards-card-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
}

.redeem-card {
	width: 90%;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
	border-radius: 10px !important;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 5px auto;
}

.redeem-icon-container-image {
	height: 110px;
	width: 120px;
}
.redeem-icon-container {
	display: flex;
}

.reward-card-text {
	font-size: 14px;
	font-weight: bold;
	margin-left: 10px;
}
.redeem-btn {
	background-color: rgb(39, 174, 96) !important;
	color: white !important;
	margin-left: 20px !important;
	margin-top: 20px !important;
}

.reward-card-group {
	padding-top: 80px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reward-display-card {
	height: 50px;
	width: 90%;
	border-radius: 10px;
	background-color: rgb(39, 174, 96);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	overflow: hidden;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.reward-display-card-title {
	font-size: 14px;
	color: white;
	text-transform: uppercase;
	font-weight: 500;
}
.reward-display-card-points {
	color: white;
	font-size: 14px;
	margin-left: 20px;
	font-weight: bold;
}
.reward-display-card-desc {
	color: black;
	font-size: 12px;
}
.points-redeemed-text {
	font-size: 10px;
	color: #aaa;
}
