.nav {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 65px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.562);
	/* background-color: #3867d6; */
	background-color: #d2dae2ef;
	display: flex;
	overflow-x: auto;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.nav__link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;
	min-width: 50px;
	overflow: hidden;
	white-space: nowrap;
	font-family: sans-serif;
	font-size: 13px;
	color: #1e272e;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
	transition: transform 0.2s;
}

.nav__icon {
	font-size: 26px;
	margin-top: 17px;
	margin-left: 5px;
}
.nav__text {
	font-size: 12px;
	margin-bottom: 3px;
	/* margin-left: 5px; */
	color: #3867d6;
	font-weight: bold;
	position: absolute;
	top: 35px;
}
