.profileContainer {
	min-height: auto;
}

.profileGrad {
	background: #0575e6; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #021b79, #0575e6); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#021b79,
		#0575e6
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	height: 400px;
	width: 100%;
	/* clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 23% 84%, 0 37%); */
	clip-path: polygon(100% -1000000%, 101% 100%, 95% 100%, 0 54%);

	position: relative;
}

.ProfilePic {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 60px;
}
.editProfileText {
	font-size: 20px;
	color: white;
	margin-top: 5px;
}

.editProfile {
	display: flex;
	justify-content: center;
	align-items: center;
}
.profilePicture {
	margin-top: 10px;
	height: 80px !important;
	width: 80px !important;
}
.editProfileCont {
	position: absolute;
	top: 225px;
	height: auto;
	margin-top: 50px;
	background-color: white;
	width: 90%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
}

.editProfileCont2 {
	position: absolute;
	top: 520px;
	height: auto;
	margin-top: 50px;
	background-color: white;
	width: 90%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
}

.editProfileCont3 {
	position: absolute;
	top: 570px;
	height: auto;
	margin-top: 50px;
	background-color: white;
	width: 90%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
}

.editProfileCont4 {
	position: absolute;
	top: 620px;
	height: auto;
	margin-top: 50px;
	background-color: white;
	width: 90%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
}

.editProfileCont5 {
	position: absolute;
	top: 680px;
	height: auto;
	margin-top: 50px;
	background-color: white;
	width: 90%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
}

.profCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.editBtn {
	font-weight: bold;
}

.editBtn0 {
	color: black;
}

.editBtn1 {
	color: #e74c3c;
}

.profileLabel {
	font-size: 16px;
	color: #bdc3c7;
}

.editCon {
	margin-top: 10px;
}
.profileText {
	font-size: 16px;
	margin-top: 2px;
	margin-bottom: 2px;
	word-wrap: break-word;
}
