.loginButton {
	position: relative;
	display: inline-block;
	padding: 12px 36px;
	color: #fff;
	font-size: 1.4rem;
	letter-spacing: 2px;
	border-radius: 5px;
	outline: none;
	border: none;
	cursor: pointer;
	box-sizing: border-box;

	// ripple
	&--ripple {
		background: linear-gradient(90deg, #0162c8, #55e7fc);
		overflow: hidden;

		span {
			position: absolute;
			background: #fff;
			transform: translate(-50%, -50%);
			pointer-events: none;
			border-radius: 50%;
			animation: rippleAnimation 1s linear infinite;
		}
		@keyframes rippleAnimation {
			0% {
				width: 0px;
				height: 0px;
				opacity: 0.5;
			}
			100% {
				width: 500px;
				height: 500px;
				opacity: 0;
			}
		}
	}

	// Glowing Gradient
	&--changeGradient {
		background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
		background-size: 400%;

		&:hover {
			animation: changeGradientAnimation 8s linear infinite;
		}
		@keyframes changeGradientAnimation {
			0% {
				background-position: 0%;
			}
			100% {
				background-position: 400%;
			}
		}
	}

	// liquid button

	&--liquidBtn {
		overflow: hidden;
		span {
			position: relative;
			z-index: 1;
		}
		&:hover div {
			top: -120px;
		}
		div {
			position: absolute;
			top: -80px;
			left: 0;
			width: 200px;
			height: 200px;
			background: #4973ff;
			box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
			transition: 0.5s;

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 50%;
				width: 200%;
				height: 200%;
				transform: translate(-50%, -75%);
			}
			&::before {
				border-radius: 45%;
				background: rgba(20, 20, 20, 1);
				animation: liquidAnimation 10s linear infinite;
			}
			&::after {
				border-radius: 40%;
				background: rgba(20, 20, 20, 0.5);
				animation: liquidAnimation 10s linear infinite;
			}
			@keyframes liquidAnimation {
				0% {
					transform: translate(-50%, -75%) rotate(0deg);
				}
				100% {
					transform: translate(-50%, -75%) rotate(360deg);
				}
			}
		}
	}

	// snake border
	&--snakeBorder {
		background: #383cc1;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			bottom: 2px;
		}

		span {
			position: absolute;

			&:nth-child(1) {
				top: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background: linear-gradient(to right, #0c002b, #1779ff);
				animation: borderAnimation1 2s linear infinite;

				@keyframes borderAnimation1 {
					0% {
						transform: translateX(-100%);
					}
					100% {
						transform: translateX(100%);
					}
				}
			}
			&:nth-child(2) {
				top: 0;
				right: 0;
				width: 2px;
				height: 100%;
				background: linear-gradient(to bottom, #0c002b, #1779ff);
				animation: borderAnimation2 2s linear infinite;
				animation-delay: 1s;

				@keyframes borderAnimation2 {
					0% {
						transform: translateY(-100%);
					}
					100% {
						transform: translateY(100%);
					}
				}
			}
			&:nth-child(3) {
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background: linear-gradient(to left, #0c002b, #1779ff);
				animation: borderAnimation3 2s linear infinite;

				@keyframes borderAnimation3 {
					0% {
						transform: translateX(100%);
					}
					100% {
						transform: translateX(-100%);
					}
				}
			}
			&:nth-child(4) {
				top: 0;
				left: 0;
				width: 2px;
				height: 100%;
				background: linear-gradient(to top, #0c002b, #1779ff);
				animation: borderAnimation4 2s linear infinite;
				animation-delay: 1s;

				@keyframes borderAnimation4 {
					0% {
						transform: translateY(100%);
					}
					100% {
						transform: translateY(-100%);
					}
				}
			}
		}
	}

	// neon
	&--neon {
		color: #03e9f4;
		overflow: hidden;
		transition: 0.5s;
		filter: hue-rotate(290deg);
		// if want reflection
		// -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);

		&:hover {
			background: #03e9f4;
			color: #050801;
			box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 200px #03e9f4;
		}
		&::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			bottom: 2px;
		}

		span {
			position: absolute;
			background: linear-gradient(180deg, transparent, #03e9f4);

			&:nth-child(1) {
				top: 0;
				left: 0;
				width: 100%;
				height: 3px;
				animation: borderAnimation1 1s linear infinite;

				@keyframes borderAnimation1 {
					0% {
						transform: translateX(-100%);
					}
					100% {
						transform: translateX(100%);
					}
				}
			}
			&:nth-child(2) {
				top: 0;
				right: 0;
				width: 3px;
				height: 100%;
				animation: borderAnimation2 1s linear infinite;
				animation-delay: 0.25s;

				@keyframes borderAnimation2 {
					0% {
						transform: translateY(-100%);
					}
					100% {
						transform: translateY(100%);
					}
				}
			}
			&:nth-child(3) {
				bottom: 0;
				left: 0;
				width: 100%;
				height: 3px;
				animation: borderAnimation3 1s linear infinite;

				@keyframes borderAnimation3 {
					0% {
						transform: translateX(100%);
					}
					100% {
						transform: translateX(-100%);
					}
				}
			}
			&:nth-child(4) {
				top: 0;
				left: 0;
				width: 3px;
				height: 100%;
				animation: borderAnimation4 2s linear infinite;
				animation-delay: 0.25s;

				@keyframes borderAnimation4 {
					0% {
						transform: translateY(100%);
					}
					100% {
						transform: translateY(-100%);
					}
				}
			}
		}
	}

	// hover effect
	&--hoverEffect {
		background: #0d0d0d;
		z-index: 1;
		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			transition: transform 0.5s;
			border-radius: 4px;
		}
		&::before {
			background: #03a9f4;
			transform: scale(0);
			transform-origin: bottom right;
		}
		&::after {
			background: transparent;
			border: 2px solid #fff;
			box-sizing: border-box;
			transform-origin: top left;
			transform: scale(1);
		}
		&:hover::before {
			transition: transform 0.5s;
			transform-origin: top left;
			transform: scale(1);
		}
		&:hover::after {
			transition: transform 0.5s;
			transform-origin: top left;
			transform: scale(0);
		}
	}

	//hover effect 2
	&--hoverEffect2 {
		display: inline-block;
		z-index: 1;
		color: #ff3c83;
		// border:3px solid #FF3C83;

		&:hover {
			color: #fff;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			right: 50%;
			bottom: 0;
			opacity: 0;
			transition: 0.5s all ease;
			box-sizing: border-box;
		}

		&:hover::before {
			border-radius: 4px;
			transition: 0.5s all ease;
			left: 0;
			right: 0;
			background: linear-gradient(90deg, #fd297a, #9424f0);
			opacity: 1;
			z-index: -1;
		}
	}

	//hover effect 3
	&--hoverEffect3 {
		display: inline-block;
		background: #fff;
		color: #333;
		overflow: hidden;
		z-index: 1;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 101%;
			width: 101%;
			clip-path: polygon(0 0, 100% 0, 10% 25%, 0 100%);
			background: #444;
			border-radius: 5px;
			transition: 0.3s cubic-bezier(0.38, 1.15, 0.7, 1.12);
			box-sizing: border-box;
			z-index: -1;
		}
		&:hover::before {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
		&:hover {
			color: #fff;
		}
	}
}
