* {
	box-sizing: border-box;
}
.App {
	height: 100vh;
}

$port-gore: #27ae60;
$gallery: white;
$white: #fff;
$riptide: #dcf8c6;
$gray: #7f7f7f;
$athens-gray: #e6eaee;
$purple-heart: #f39c12;

$chat-height: 420px;
// $chat-max-width: 420px;
$chat-max-width: 380px;
$chat-distance-right-window: 50px;
$chat-distance-bottom-window: 50px;

// $chat-padding: 20px;
$chat-padding: 7px;
$header-height: 54px;
$entry-height: 70px;

#chat-circle {
	position: fixed;
	bottom: 50px;
	right: 50px;
	// background: #262a53;
	background-color: $port-gore;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	color: white;
	padding: 10px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.hide--bot {
	display: none;
}

.chatbot {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100%;
	// box-shadow: 0 -6px 99px -17px rgba(0, 0, 0, 0.68);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	border-radius: 10px;
	z-index: 9999;

	display: flex;
	flex-direction: column;

	@media screen and (min-width: 640px) {
		max-width: $chat-max-width;
		right: $chat-distance-right-window;
		bottom: $chat-distance-bottom-window;
		height: 80vh;

		top: auto;
	}

	&.chatbot--closed {
		top: auto;
		width: 100%;
	}
}
.chat-height {
	// height: calc(100vh - 50vh);
	height: 360px;

	@media screen and (min-width: 640px) {
		height: 360px;
	}
}

.chatbot__header {
	color: $white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $port-gore;
	height: $header-height;
	padding: 0 $chat-padding;
	width: 100%;
	cursor: pointer;
	transition: background-color 0.2s ease;

	@media screen and (min-width: 640px) {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	&:hover {
		background-color: lighten($port-gore, 10%);
	}

	p {
		margin-right: $chat-padding;
	}
}

.chathome__header {
	color: $white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $port-gore;
	padding: 10px;
	width: 100%;
	transition: background-color 0.2s ease;

	height: 70px;
	@media screen and (min-width: 640px) {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	// &:hover {
	// 	background-color: lighten($port-gore, 10%);
	// }
	h3 {
		margin-left: auto;
		margin-right: auto;
		font-weight: 400;
		font-size: 1.1rem;
		text-align: center;
	}
}

.chatbot__close-button {
	fill: $white;

	&.icon-speech {
		width: 20px;
		display: none;

		.chatbot--closed & {
			display: block;
		}
	}

	&.icon-close {
		width: 14px;

		.chatbot--closed & {
			display: none;
		}
	}
}

.chatbot__message-window {
	height: calc(100% - 50px);
	// height: calc(100% - 140px);
	padding: ($chat-padding * 2) $chat-padding $chat-padding;
	background-color: $white;
	overflow-x: none;
	overflow-y: auto;
	// background-image: url("./assets/livesloka_logo.png");
	background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
	background-repeat: repeat;
	background-position: center;
	position: relative;

	// @media screen and (min-width: 640px) {
	// 	height: $chat-height;
	// }

	&::-webkit-scrollbar {
		display: none;
	}

	.chatbot--closed & {
		display: none;
	}
}

.chathome__message-window {
	// height: calc(100% - (70px+ 500px));
	padding: 10px 20px 2px 20px;
	padding: 0px 20px 2px 20px;
	background-color: $white;
	overflow-x: none;
	flex-grow: 1;
	position: relative;

	// overflow-y: auto;
	// background-image: url("./assets/livesloka_logo.png");
	// background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
	// background-repeat: repeat;
	// background-position: center;

	// @media screen and (min-width: 640px) {
	// 	height: $chat-height;
	// }

	&::-webkit-scrollbar {
		display: none;
	}

	.chatbot--closed & {
		display: none;
	}
}

.chatbot_logo {
	width: 241px;
	height: 98%;
	text-align: center;
}
.chatbot__messages {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	width: auto;
	min-height: 100%;
	justify-content: flex-end;
	li {
		margin-bottom: $chat-padding;

		&.is-ai {
			display: inline-flex;
			align-items: flex-start;
		}

		&.is-user {
			text-align: right;
			display: inline-flex;
			align-self: flex-end;
		}

		.is-ai__profile-picture {
			// margin-right: 8px;

			.icon-avatar {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				padding-top: 6px;
			}
		}
	}
}

.chatbot__message {
	display: inline-block;
	// padding: 12px $chat-padding; before
	padding: 7px $chat-padding;

	word-break: break-word;
	margin: 0;
	border-radius: 6px;
	letter-spacing: -0.01em;
	line-height: 1.45;
	// overflow: hidden;
	position: relative;
	text-align: left;

	cursor: pointer;
	font-size: small;
	min-width: 80px;
	padding-bottom: 12px;

	.is-ai & {
		background-color: $gallery;
		margin-right: $chat-padding * 1.5;
	}

	.is-user & {
		background-color: $riptide;
		margin-left: $chat-padding * 1.5;
	}

	a {
		color: $purple-heart;
		word-break: break-all;
		display: inline-block;
	}

	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	.button {
		background-color: $white;
		font-weight: 300;
		border: 2px solid $purple-heart;
		border-radius: 50px;
		padding: 8px 20px;
		margin: -8px 10px 18px 0;
		transition: background-color 0.2s ease;
		cursor: pointer;

		&:hover {
			background-color: darken($white, 05%);
		}
		&:focus {
			outline: none;
		}
	}

	img {
		max-width: 100%;
	}

	.card {
		background-color: $white;
		text-decoration: none;
		overflow: hidden;
		border-radius: 6px;
		color: black;
		word-break: normal;

		.card-content {
			padding: $chat-padding;
		}

		.card-title {
			margin-top: 0;
		}

		.card-button {
			color: $purple-heart;
			text-decoration: underline;
		}
	}
	// &:hover button {
	// 	display: block !important;
	// }
}

.animation {
	&:last-child {
		animation: fadein 0.25s;
		animation-timing-function: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}

.chatbot__arrow {
	width: 0;
	height: 0;
	margin-top: 18px;
}

.chatbot__arrow--right {
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid $riptide;
}

.chatbot__arrow--left {
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-right: 6px solid $gallery;
}

.chatbot__entry {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: $entry-height;
	padding: 0 $chat-padding;
	border-top: 1px solid $athens-gray;
	background-color: $white;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	height: 50px;
	.chatbot--closed & {
		display: none;
	}
}

.chathome__entry {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// height: $entry-height;
	padding: 10px 0;
	background-color: $white;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-top: auto;
}
.chatbot__input {
	height: 100%;
	width: 80%;
	border: 0;
	flex: 1;

	&:focus {
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: $gray;
	}
	&::-moz-placeholder {
		color: $gray;
	}
	&::-ms-input-placeholder {
		color: $gray;
	}
	&::-moz-placeholder {
		color: $gray;
	}
}

.chatbot__submit {
	fill: $purple-heart;
	height: 22px;
	width: 22px;
	transition: fill 0.2s ease;
	cursor: pointer;
	margin-left: 10px;
	&:hover {
		fill: darken($purple-heart, 20%);
	}
}

.u-text-highlight {
	color: $riptide;
}

//Animated Loader
//----------------
.loader {
	margin-bottom: -2px;
	text-align: center;
	opacity: 0.3;
}

.loader__dot {
	display: inline-block;
	vertical-align: middle;
	width: 6px;
	height: 6px;
	margin: 0 1px;
	background: black;
	border-radius: 50px;
	animation: loader 0.45s infinite alternate;
	&:nth-of-type(2) {
		animation-delay: 0.15s;
	}
	&:nth-of-type(3) {
		animation-delay: 0.35s;
	}
}

// KeyFrames
@keyframes loader {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-5px);
	}
}

@keyframes fadein {
	from {
		opacity: 0;
		margin-top: 10px;
		margin-bottom: 0;
	}
	to {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 10px;
	}
}

.typing {
	align-items: center;
	display: flex;
	height: 17px;
}
.typing .dot {
	animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
	background-color: $port-gore; //rgba(20,105,69,.7);
	border-radius: 50%;
	height: 7px;
	margin-right: 4px;
	vertical-align: middle;
	width: 7px;
	display: inline-block;
}
.typing .dot:nth-child(1) {
	animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
	animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
	animation-delay: 400ms;
}
.typing .dot:last-child {
	margin-right: 0;
}

@keyframes mercuryTypingAnimation {
	0% {
		transform: translateY(0px);
		background-color: $port-gore; // rgba(20,105,69,.7);
	}
	28% {
		transform: translateY(-7px);
		background-color: #9ecab9; //rgba(20,105,69,.4);
	}
	44% {
		transform: translateY(0px);
		background-color: #b5d9cb; //rgba(20,105,69,.2);
	}
}
.chatbot__chatname {
	// font-size: 10px;
	font-size: 8px;
	color: #000000;
	font-weight: bolder;
	letter-spacing: 1px;
	// position: absolute;
	// right: 6px;
	// bottom: -3px;
}

.MuiAvatar-colorDefault {
	color: #fafafa;
	background-color: rgb(39, 174, 96) !important;
}

.chatTime {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
}

.chat__reply {
	padding: 5px 10px 5px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #2a2f32;
}

.chat__reply-message {
	width: 100%;
	background-color: #dddbd1;
	padding: 7px;
	border-radius: 10px;
	border: 0px solid #35cd96;
	border-width: 0 0 0 5px;
	font-size: 0.85rem;
	font-size: small;
}
.chat__reply-name {
	display: block;
	font-size: 0.7rem;
	font-weight: bold;
}

.chat__replys {
	display: flex;
	border-radius: 10px;
	flex-direction: column;
	// justify-content: center;
	// align-items: center;
	color: #2a2f32;
	min-width: 150px;
	text-align: left;
	background-color: $riptide;
	position: relative;

	&:hover button {
		display: block !important;
	}
}

.chat__replys-message {
	width: 100%;
	background-color: #dddbd1;
	padding: 4px;
	border-radius: 10px 10px 0 0;
	border: 0px solid #35cd96;
	border-width: 0 0 0 5px;
	// font-size: 0.85rem;
	font-size: small;
}
.chat__replys-name {
	display: block;
	// font-size: 0.7rem;
	font-size: 8px;
	font-weight: bold;
}
.chat__replys-main {
	padding: 0 10px;
	display: inline-block;
	padding: 7px $chat-padding;
	word-break: break-word;
	margin: 0;
	border-radius: 6px;
	letter-spacing: -0.01em;
	line-height: 1.45;
	// overflow: hidden;
	text-align: left;
	cursor: pointer;
	font-size: small;
	padding-bottom: 12px;
}
.chat--time {
	margin-left: 10px;
	font-size: 8px;
	position: absolute;
	right: 8px;
	bottom: 0px;
}
.chat-group-image {
	margin-right: 16px;
}
.chat-list-item {
	padding: 0 10px !important;
}

.chat-list-item--text {
	padding: 0 !important;
}

.file__attach .MuiSpeedDial-fab {
	width: 35px;
	height: 35px;
}
