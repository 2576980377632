.notification-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 100px;
	margin: 5px auto;
	padding: 8px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.icon-wrapper {
	height: 100%;
	border-left: 5px solid #fed330;
	padding: 15px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-circle {
	height: 35px;
	width: 35px;
	background-color: #fed330;
	color: #fff;
	display: flex;
	border-radius: 50%;
}

.close-icon > div,
.icon-circle > div {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.close-icon {
	cursor: pointer;
}

.text-area {
	flex: 1;
}

.text-area .title {
	display: block;
	background-color: transparent;
	border: none;
	font-size: 1rem;
	outline: none;
	font-family: "Titillium Web", sans-serif;
	text-align: left;
}

.text-area .text {
	width: 100%;
	display: block;
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 1rem;
	height: auto;
	font-family: "Titillium Web", sans-serif;
	resize: none;
	font-size: 0.7rem;
}
