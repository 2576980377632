.card-imgs {
	height: 20px;
	width: 20px;
	margin-left: 5px;
	margin-top: 2px;
}

.error-message {
	margin-top: -13px;
	font-size: 12px;
	font-weight: bold;
	color: #e74c3c;
	margin-left: 5px;
}
