.leaves-container {
	margin-top: 60px;
	padding: 20px;
	box-sizing: border-box;
	margin-bottom: 50px;
}

.leaves-container h2 {
	margin: 10px 0px 20px;
}

.leaves-container .margin-left-20 {
	margin-left: 20px;
}

.leaves-container .card {
	position: relative;
	height: 100%;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(0, 0, 0, 0.212) 0px 0px 0px 1px;
	border-radius: 15px;
}

.leaves-container .top-icons {
	position: absolute;
	top: 10px;
	right: 10px;
}

.leaves-container .card .caption {
	color: #4834d4;
}

.leaves-container .card .date {
	font-weight: 800;
}

.leaves-container .card .caption,
.leaves-container .card .date {
	/* width: 99%; */
	font-size: 12px;
}

.leaves-container .divider-styles {
	margin: 20px 0;
}

.leaves-container .card .side-badge {
	position: absolute;
	right: -12px;
	bottom: 10px;
}
