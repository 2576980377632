.firstCard {
	height: 50px;
	background: #3f51b5; /* fallback for old browsers */

	/* background-image: url(https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-repeat: no-repeat;
  background-size: auto;
  background-size: 100% 150px; */
}

.cardContariner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.SingleCardCon {
	border-width: 3px;
	border-color: #d1d8e0;
	border-style: solid;
	height: 210px;
	border-radius: 15px;
	width: 90%;
	margin-top: 10px;
	margin-bottom: 10px;
	transition: 0.5s ease-in;
}

.SingleCardCon:hover {
	transform: scale(1.02);
	background-position: top;
}

.testing {
	width: 100%;
}
.searchInput {
	margin-top: 30px;
	width: 95%;
	margin-bottom: 10px;
	border: 2px solid #d1d8e0;
	border-radius: 5px;
	height: 40px;
	outline: none;

	background: rgba(255, 255, 255, 0.45);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(8.5px);
	-webkit-backdrop-filter: blur(8.5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}
.inputfieldsearch {
	position: absolute;
	left: 17px;
	top: 93px;
	font-size: 34px !important;
	color: #95a5a6ee;
	z-index: 1;
}
