.teacher-attendance .title {
	text-align: center;
	margin-top: 70px;
}

.teacher-attendance .attendance-card {
	display: flex;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	width: 90%;
	margin: 20px auto;
	padding: 10px;
	border-radius: 5px;
	align-items: center;
}

.teacher-attendance .attendance-card .student-name {
	flex: 1;
	padding: 10px;
}
