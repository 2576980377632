@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

* {
	font-family: "Poppins", sans-serif;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #075e89;
	border-radius: 10px;
}

body {
	width: 100vw;
	height: auto;
	overflow-x: hidden;
}

.auto-container {
	max-width: 1200px;
	margin: 0 auto;
	margin-bottom: 40px;
	overflow: hidden;
	border-radius: 10px;
}

.top-courses {
	padding: 20px;
}

h3.heading {
	font-weight: 700;
	font-size: 2.5rem;
	margin-bottom: 20px;
}

h5.heading {
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 2rem;
	color: #03382e;
	margin: 30px;
}

p.head-desc {
	font-weight: 400;
	line-height: 30px;
	font-size: 16px;
	color: #626262;
	margin-bottom: 40px;
	max-width: 900px;
}

.btn-glaze {
	display: flex;
	align-items: center;
	background-color: #075e89;
	color: #fff;
	padding: 10px 15px;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	z-index: 20;
}

.btn-glaze::after {
	background: #fff;
	content: "";
	height: 155px;
	left: -75px;
	opacity: 0.2;
	position: absolute;
	top: -50px;
	transform: rotate(35deg);
	transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
	width: 50px;
	z-index: -10;
}

.btn-glaze:hover::after {
	left: 120%;
	transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card {
	width: 100%;
	padding: 20px;
}

.card .card-img {
	width: 100%;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	cursor: pointer;
}

.card .card-text {
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	position: relative;
	padding-bottom: 80px;
}

.card .card-text .head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.card .card-text .head > h5 {
	font-size: 1.2rem;
	line-height: 26px;
	font-weight: 600;
	cursor: pointer;
}

.card .card-text .head > h5:hover {
	color: #00ab15;
}

.card .card-text .desc {
	font-size: 1rem;
	line-height: 29px;
	color: #626262;
	font-weight: 400;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.card .card-text .bottom {
	position: absolute;
	bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(100% - 40px);
}

.card .card-text .bottom > button {
	background-color: #00ab15;
	border: none;
	padding: 8px 20px;
	border-radius: 20px;
	color: #fff;
	cursor: pointer;
}

.text-align-center {
	text-align: center;
}

.margin-auto {
	margin: 0 auto;
}

.oa-card-wrapper {
	width: 100%;
	padding: 20px;
}

.oa-card {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: baseline;
	padding: 30px;
}

.oa-card .icon-wrapper {
	width: 100px;
	height: 100px;
	background-color: #b4e9f9;
	border-radius: 50%;
	display: grid;
	place-items: center;
	margin-bottom: 30px;
}

.auto-container .icon-wrapper.red {
	background-color: #fbdddd;
}

.auto-container .icon-wrapper.sand {
	background-color: #fbce8b;
}

.oa-text {
	font-weight: 700;
	font-size: 1.6rem;
	line-height: 42px;
	color: #03382e;
}
.oa-number {
	font-weight: 700;
	font-size: 4rem;
	line-height: 4rem;
	color: #03382e;
}

.top-name {
	width: 100vw;
	height: 40vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #075e89;
	color: #fff;
	margin-bottom: 30px;
	position: relative;
}

.top-name h2 {
	font-weight: 400;
	font-size: 38px;
	line-height: 49px;
	text-align: center;
}

.light-blue-bg {
	background-color: #f0f5fb;
}

.course-info-container {
	margin: 10px;
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
}

.people-registered {
	font-weight: 500;
	font-size: 1rem;
	line-height: 21px;
	color: #393939;
}

.sub-heading {
	font-weight: 600;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: #03382e;
	margin-top: 30px;
}

.instructor-img-wrapper {
	padding: 20px;
	width: 100%;
}

.instructor-img {
	width: 100%;
	object-fit: cover;
	height: 370px;
	border-radius: 10px;
}

.instructor-info {
	text-align: center;
}

.instructor-info .name {
	font-weight: 700;
	font-size: 1.3rem;
	line-height: 1.5rem;
	color: #03382e;
}

.instructor-info .desc {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.1rem;
	color: #626262;
	margin-top: 10px;
}

.course-img-container {
	padding: 10px 30px;
	width: 100%;
	box-sizing: border-box;
}

.course-img-container .course-img {
	width: 100%;
	border-radius: 10px;
}

.schedule-slot-card {
	width: calc(100% - 5px);
	background-color: #fff;
	border-radius: 10px;
	margin: 30px auto;
	position: relative;
	cursor: pointer;
	padding: 10px 0;
}

.pos-radio {
	position: absolute;
	right: 0;
}

.schedule-slot-card .amount {
	text-align: center;
	font-weight: 700;
	font-size: 2rem;
	line-height: 1.5rem;
	color: #03382e;
	margin: 20px 0;
}

.schedule-slot-card ul {
	list-style: circle;
	font-size: 0.7rem;
	line-height: 1.2rem;
	color: #626262;
	font-weight: 400;
	padding: 0 20px;
}

.imagecarousel {
	height: 100vh;
	width: 100vw;
	object-fit: contain;
}

.course-amount {
	text-align: center;
	font-size: 3rem;
	line-height: 2.5rem;
	font-weight: 700;
	color: #075e89;
}

.login-btn {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background: transparent;
	color: #fff;
	border: none;
	outline: none;
	cursor: pointer;
}

.header-logo {
	height: 70px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
		rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
		rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media only screen and (max-width: 600px) {
	h3.heading {
		font-size: 2rem;
	}
	p.head-desc {
		padding: 10px;
	}
	.imagecarousel {
		height: 220px;
	}

	::-webkit-scrollbar {
		width: initial;
	}

	::-webkit-scrollbar-track {
		background: initial;
	}

	::-webkit-scrollbar-thumb {
		background: initial;
	}

	::-webkit-scrollbar-thumb:hover {
		background: initial;
	}
}
